import React from "react";
import styles from './main.module.css';
import { FaFan, FaLightbulb } from 'react-icons/fa';
import { GiWindow } from 'react-icons/gi';
import AppContext from "../../components/AppContext";
import { motion, AnimatePresence } from 'framer-motion';
import update from 'immutability-helper';

let formatConfig = {
    temperatureThreshold: 100
};

export default class Home extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {
            dataControllerModalVariants: {
            }
        }
    }

    getIcon(type) {
        if (type.split("/")[0] === "binary") {
            switch (type.split("/")[1]) {
                case "damper":
                    return <GiWindow size="1.8em" />;
                case "fan":
                    return <FaFan size="1.8em" />;
                case "light":
                    return <FaLightbulb size="1.8em" />;
                default:
                    return <div className={styles.unknown}></div>;
            }
        }
    }

    format(value, controllerType, big) {
        if (big) {
            if (controllerType.includes("temperature")) {
                let color = (value > formatConfig.temperatureThreshold) ? "#ff7300" : "#0075ff";
                return (
                    <div className={styles.bigTemp}>
                        <span>{value}</span>
                        <svg height="300" width="300" style={{ transform: "rotate(130deg)" }}>
                            <circle
                                fill="transparent"
                                stroke="rgba(0,0,0,0.1)"
                                strokeWidth={5}
                                strokeDasharray={750}
                                strokeDashoffset={750 - (250 / 385) * 750}
                                cx="150" cy="150" r="100"
                            />
                            <motion.circle
                                fill="transparent"
                                stroke={color}
                                strokeWidth={5}
                                strokeDasharray={750}
                                strokeDashoffset={750 - (value / 385) * 750}
                                cx="150" cy="150" r="100"
                            />
                        </svg>
                    </div>
                )
            }
        } else {
            if (controllerType.includes("temperature")) {
                return (
                    <span className={
                        (value > formatConfig.temperatureThreshold) ?
                            styles.tempHigh :
                            styles.tempLow
                    }>
                        {value}{"°"}
                    </span>
                );
            }
        }
        return (<span className={styles.readonlyValue}>{value}</span>);
    }

    render() {
        return (
            <div className={styles.container}>
                <h1>My Home</h1>
                <div className={styles.wrapper}>
                    {this.context.devices.length > 0 ? (
                        this.context.devices.map(device => {
                            return (
                                <div key={device.id} className={styles.deviceContainer}>
                                    <span>{device.location}</span>
                                    <div className={styles.deviceWrapper}>
                                        {device.controllers?.filter(c => c.type.startsWith("#!")).map(controller => (
                                            <div
                                                key={controller.id} className={styles.dataDeviceLink}>
                                                <AnimatePresence>
                                                    <motion.div
                                                        variants={{
                                                            open: {
                                                                height: window.outerHeight / 2,
                                                            },
                                                            closed: {
                                                                height: "100px"
                                                            }
                                                        }}

                                                        onClick={() => {
                                                            let oldObj = this.state.dataControllerModalVariants;
                                                            if (oldObj[device.id] === undefined) oldObj[device.id] = {};
                                                            if (oldObj[device.id][controller.id] === undefined) oldObj[device.id][controller.id] = "open";
                                                            else oldObj[device.id][controller.id] = oldObj[device.id][controller.id] === "closed" ? "open" : "closed";
                                                            this.setState({
                                                                dataControllerModalVariants: oldObj
                                                            });
                                                        }}
                                                        style={{ overflow: "hidden" }}
                                                        animate={this.state.dataControllerModalVariants[device.id] && this.state.dataControllerModalVariants[device.id][controller.id]}
                                                        className={styles.dataDevice}>
                                                        {this.state.dataControllerModalVariants[device.id] && this.state.dataControllerModalVariants[device.id][controller.id] === "open" ? (
                                                            <div className={styles.giantDataView}>
                                                                {this.format(device.state[controller.id], controller.type, true)}
                                                                <span>{controller.location}</span>
                                                                <p>Just Now</p>
                                                            </div>
                                                        ) : (
                                                            <>
                                                                {this.format(device.state[controller.id], controller.type)}
                                                                <div className={styles.holder}>
                                                                    <span>{controller.name}</span>
                                                                    <span>Fahrenheit</span>
                                                                </div>
                                                            </>
                                                        )}
                                                    </motion.div>
                                                </AnimatePresence>
                                            </div>
                                        ))}
                                        {device.controllers?.filter(c => !c.type.startsWith("#!")).map(controller => (
                                            <motion.div
                                                key={controller.id}
                                                whileTap={{ scale: 0.9 }}
                                                transition={{ type: "spring", stiffness: 500, damping: 13 }}
                                                className={styles.link}
                                                onClick={() => {
                                                    let isOn = device.state[controller.id];
                                                    if (!this.context.debug) {
                                                        this.context.wss.send(JSON.stringify({
                                                            command: "setstate",
                                                            payload: {
                                                                device: device.id,
                                                                state: {
                                                                    [controller.id]: !isOn
                                                                }
                                                            }
                                                        }));
                                                    }
                                                }}
                                            >
                                                <div className={styles.device + " " + (device.state[controller.id] ? styles.on : styles.off)}>
                                                    {this.getIcon(controller.type)}
                                                    <div className={styles.holder}>
                                                        <span>{controller.name}</span>
                                                        <span>{device.state[controller.id] ? "ON" : "OFF"}</span>
                                                    </div>
                                                </div>
                                            </motion.div>
                                        ))}
                                    </div>
                                </div>
                            )
                        })) :
                        (
                            <h3>No Devices Available</h3>
                        )
                    }
                </div>
            </div >
        );
    }
}