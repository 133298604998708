import React from "react";
import styles from './main.module.css';
import AppContext from "../../components/AppContext";
import withRouter from '../../components/withRouter';
import { IoChevronBack } from 'react-icons/io5';
import { GiWindow } from 'react-icons/gi';
import { FaFan, FaLightbulb } from 'react-icons/fa';

class Controller extends React.Component {
    static contextType = AppContext;

    getIcon(type) {
        if (type.split("/")[0] === "binary") {
            switch (type.split("/")[1]) {
                case "damper":
                    return <GiWindow size="1.3em" />;
                case "fan":
                    return <FaFan size="1.3em" />;
                case "light":
                    return <FaLightbulb size="1.3em" />;
                default:
                    return <div className={styles.unknown}></div>;
            }
        }
    }

    render() {
        let deviceId = this.props.device?.id ?? this.props.router.params.id;
        let controllerId = this.props.controller?.id ?? this.props.router.params.controller;

        let device = this.context.devices.find(device => device.id === deviceId);
        if (!device) return <span>Error</span>;
        let controller = device.controllers.find(controller => controller.id === controllerId);
        if (!controller) return <span>Error</span>;
        let isOn = device.state[controller.id];
        return (
            <div className={styles.container}>
                <div className={styles.header}>
                    <button
                        onClick={() => {
                            this.props.router.navigate(-1);
                        }}
                    ><IoChevronBack />Home</button>
                    <span>{controller.location}</span>
                </div>
                <div className={styles.info}>
                    <div className={styles.name}>
                        {this.getIcon(controller.type)}
                        <span>{controller.name}</span>
                    </div>
                    <button
                        style={{
                            background: isOn ? "rgb(80,150,255)" : "gray",
                        }}
                        onClick={() => {
                            this.context.wss.send(JSON.stringify({
                                command: "setstate",
                                payload: {
                                    device: device.id,
                                    state: {
                                        [controller.id]: !isOn
                                    }
                                }
                            }));
                        }}
                    >
                        {isOn ? "ON" : "OFF"}
                    </button>
                </div>
                <div className={styles.spacer} >
                    <span>Device: {device.description}</span>
                    <span>Device Id: {device.id}</span>
                    <div className={styles.divider} />
                    <span>Controller: {controller.name}</span>
                    <span>Controller Id: {controller.id}</span>
                    <span>Controller Location: {controller.location}</span>
                    <span>Controller Type: {controller.type}</span>
                </div>
            </div>
        )
    }
}

export default withRouter(Controller);