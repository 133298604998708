import React from 'react';
import './App.css';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import AppContext from './components/AppContext';

// Pages
import Home from './pages/home/main';
import Controller from './pages/controller/main';
import { io } from 'socket.io-client';

const wss = io("wss://homeserver.microart.app/app");

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            devices: [],
            wss: wss,
            debug: false
        }
        if (this.state.debug) this.state.devices = [
            {
                id: "2AFA1SV00CU",
                description: "Multi use controller to control the smart vent, some LEDs, and the attic fans.",
                type: "multi",
                location: "Attic",
                capabilities: {
                    "smartVent": 0,
                    "atticFans": 0,
                    "onboardLed": 0,
                    "altLed": 0
                },
                controllers: [
                    {
                        id: "smartVent",
                        name: "Smart Vent",
                        location: "Attic",
                        type: "binary/damper",
                        states: [true, false]
                    },
                    {
                        id: "atticFans",
                        name: "Attic Fans",
                        location: "Attic",
                        type: "binary/fan",
                        states: [true, false]
                    },
                    {
                        id: "onboardLed",
                        name: "Onboard LED",
                        location: "Attic",
                        type: "binary/light",
                        states: [true, false]
                    },
                    {
                        id: "altLed",
                        name: "Alternate LED",
                        location: "Attic",
                        type: "binary/light",
                        states: [true, false]
                    },
                    {
                        id: "temperature",
                        name: "Temperature",
                        location: "Attic",
                        type: "#!integer/temperature",
                        states: [0]
                    }
                ],
                state: {
                    smartVent: false,
                    atticFans: false,
                    onboardLed: false,
                    altLed: false,
                    temperature: 80
                }
            }
        ]
    }

    componentWillUnmount() {
        wss.onopen = null;
        wss.onmessage = null;
    }

    componentDidMount() {
        if (!this.state.debug) {
            wss.onmessage = (event) => {
                let data = JSON.parse(event.data);
                if (data.command === "devices") {
                    this.setState({ devices: data.payload });
                } else if (data.status === 'update') {
                    let devices = [];
                    this.state.devices.forEach(device => {
                        let newState = data.payload.find(payload => payload.id === device.id).state;
                        if (newState) {
                            devices.push({
                                ...device,
                                state: newState
                            });
                        } else {
                            devices.push(device);
                        }
                    });
                    this.setState({ devices: devices });
                }
            }
            wss.onopen = () => {
                wss.send(JSON.stringify({ command: "devices" }));
            }
        }
    }

    render() {
        return (
            <>
                <AppContext.Provider
                    value={{
                        devices: this.state.devices,
                        wss: this.state.wss
                    }}
                >
                    <BrowserRouter>
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/:id/:controller" element={<Controller />} />
                        </Routes>
                    </BrowserRouter>
                </AppContext.Provider>
            </>
        );
    }
}

export default App;
